
import './Menu.css';
import {easeIn, easeInOut, easeOut, motion} from 'framer-motion';
import CurrentTime from './CurrentTime';
import { Link } from 'react-router-dom';
const Menu = ({ isOpen, onClose }) => {
  const menuAnimation = {
    hidden: { y: '-100%', opacity: 1 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.8,ease:easeOut } },
    
  };
  const goBackAnimation = {
    hidden: { y: 0, opacity: 1 },
    visible: { y: '-100%', opacity: 0, transition: { duration: 1.3 } },
  };

  return (
    <div className='mobilemenu'>
  
    <motion.div
   
    variants={isOpen ? menuAnimation : goBackAnimation}
    initial={'hidden'}
    animate={'visible' }
     className='h-full  flex justify-between w-full text-black flex-col menu pt-[5.5rem]  items-start pl-10'>
     <div className='flex-row flex justify-center items-center w-full gap-48 menunav border-b-2 bg-slate-300 font-extrabold ml-[-40px]'>
      <h1 className=' text-black '>Talha Chinchali</h1>
      <h1 className=' text-black' onClick={onClose}>close</h1>
      </div>
      <button className="menu-item text-3xl  mt-40 -mb-10 font-extrabold" onClick={onClose}>   <Link to='/' className='bg-transparent'>Home</Link></button>
      <button className="menu-item text-3xl -mb-10  font-extrabold"><Link to='/projects' className='bg-transparent'>PROJECTS </Link></button>
      <button className="menu-item text-3xl -mb-10  font-extrabold"><Link to='/skills' className='bg-transparent'>SKILLS</Link> </button>
      
      <button className="menu-item text-3xl -mb-10  font-extrabold"><Link to='/contact' className='bg-transparent'>CONTACT </Link></button>
     <div className='w-[100vw] mainfoot flex-row border-t-2 bg-transparent ml-[-40px] h-[7vw]'>
      <button className='foot '>
        2023
      </button>
      <h3 className="font-extrabold text-lg bg-transparent text-white w-fit"><CurrentTime /> </h3>
      </div>
    </motion.div>
    </div>
  );
};

export default Menu;
