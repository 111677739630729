import React, { useState, useEffect } from 'react';
import './Skills.css';
import { easeInOut, motion, easeIn, easeOut } from 'framer-motion';
import FontAwesomeIcon from '@fortawesome/fontawesome-free/css/all.min.css';
import Js from './java-script.png'
import ReactPng from './icons8-react-js-400.png';
import NodePng from './icons8-nodejs-600.png';
import ExpressPng from './icons8-express-js-480.png';
import MongoPng from './icons8-mongodb-480.png';
import JavaPng from './icons8-java-480.png';
import CppPng from './icons8-c++-480.png';
import AlgoPng from './algorithm.png';
import LargeMenu from './LargeMenu';
import Menu from './Menu';
import LocomotiveScroll from 'locomotive-scroll';
import './Project.css';

const Skills = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // 1400 milliseconds delay

    return () => clearTimeout(timeout);
  }, []);
  const handleMenuClick = () => {

    setMenuOpen(true);
  };
  const handleGoBackClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const scrollEl= document.querySelector('.h');
    if(scrollEl){
      console.log("successfully");
  const locoScroll=new LocomotiveScroll({
    el:scrollEl,
    smooth:true,
    multiplier:2.5,
  })}
  else{
    console.log("element not found");
  }
  
  }, [isLoading])

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });

  };
  return (
    <>
      {isLoading ? (
        <motion.div
          initial={{ y: 0 }}
          animate={{
            y: ["100%", "0%", "0%", "-100%"],
            transitionEnd: { y: "-100%" }
          }}

          transition={{ duration: 4, times: [0, 0.25, 0.875, 1], delay: 0, ease: [easeInOut, easeInOut, easeInOut, easeIn] }}
          className='w-[100vw] h-[100vh] bg-[#a3a3a3] text-white flex justify-center items-center mainskill relative z-[10]'
        >
          <h1 data-text="Talha Chinchali">Talha Chinchali</h1>
        </motion.div>
      ) : (<div className='h-[100vh]'>
      <div className='dot-wrapper' >
        <div id="dot" className='w-3 h-3 bg-white rounded-full z-50 fixed dot ' innerText='View' style={{ top: mousePosition.y, left: mousePosition.x }}></div></div>
       <nav className="nav top-0" onMouseMove={handleMouseMove}>
            <button className="text-white text-lg">Talha Chinchali</button>

            {isMenuOpen && <LargeMenu />}
            {!isMenuOpen && <h1 className="menuplus text-white h-fit text-lg cursor-pointerr " onClick={handleMenuClick} >Menu++</h1>}
          </nav>
          {isMenuOpen && <div className=' '><Menu isOpen={isMenuOpen} onClose={handleGoBackClick} /></div>}
        <div className='text-white w-full h-fit flex flex-col  flex-wrap gap-20 justify-center items-center p-10 pl-[1rem] h' onMouseMove={handleMouseMove}>
         
          <div className='allthecontent  rounded-[20px] flex flex-wrap justify-between w-[90vw] border-2 mt-[6vw] p-10  items-center '>
            <h1 className='sideHeading text-[5vw] opacity-60 mb-[10vw]'>FRONTEND</h1>
            <div className='flex flex-wrap items-center j justify-end  gap-10 '>

              <div className='w-fit bg-white h-[15vw] overflow-hidden relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <img className='w-[15vw] bg-transparent b rounded-xl' src='https://www.logolynx.com/images/logolynx/4f/4fc5eb035a93e267c23a909f21a87f5e.png' />

                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>


              <div className='w-fit bg-white h-[15vw] overflow-hidden relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <img className='w-[15vw] bg-transparent b rounded-xl' src='http://4.bp.blogspot.com/-z-FiEqHNMrI/UA2Qu1plIGI/AAAAAAAAAm0/pFFmjHyr4x8/s1600/css3+logo.png' />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>



              <div className='w-fit bg-white h-[15vw] overflow-hidden relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <img className='w-[12vw] bg-transparent b rounded-xl mt-1' src={Js} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw] "></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>


            </div>
          </div>


          <div className=' rounded-[20px] flex flex-wrap h-fit justify-between w-[90vw] border-2 p-10 items-center'>
            <h1 className='sideHeading text-[5vw] opacity-60 mb-[5vw]'>FULL STACK</h1>
            <div className='flex flex-wrap items-center j justify-end  gap-10 '>

              <div className='w-fit bg-white h-[15vw] overflow-hidden p-4 flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>REACT</h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={ReactPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>



              <div className='w-fit p-4 bg-white h-[15vw] overflow-hidden flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>Node JS</h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={NodePng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>



              <div className='w-fit p-4 bg-white h-[15vw] overflow-hidden flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>Express JS</h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={ExpressPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />   <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>



              <div className='w-fit p-4 bg-white h-[15vw] overflow-hidden flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>Mongo DB</h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={MongoPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>


            </div>
          </div>


          <div className=' rounded-[20px] flex flex-wrap justify-between w-[90vw] border-2 p-10 items-center'>
            <h1 className='sideHeading text-[5vw] opacity-60'>LANGUAGES</h1>
            <div className='flex flex-wrap items-center j justify-end  gap-10 '>

              <div className='w-fit p-4 bg-white h-[15vw] overflow-hidden flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>Core Java</h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={JavaPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                </div>
              </div>



              <div className='w-fit p-4 bg-white h-[15vw] overflow-hidden flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>C++ </h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={CppPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />   <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>

                </div>
              </div>



            </div>
          </div>


          <div className='  rounded-[20px] flex justify-between w-[90vw] flex-wrap border-2 p-10 items-center'>
            <p className='sideHeading text-[4vw] opacity-60 w-fit '>DATA STRUCTURES<br /> & ALGORITHMS</p>
            <div className='flex flex-wrap w-full items-center  justify-end  gap-10 '>

              <div className=' p-4 bg-white h-[15vw] overflow-hidden  flex justify-center items-center flex-col relative  maindiv group transition-all duration-1000 ease-in-out glass'>
                <div className='p-4 pt-1 flex justify-center items-center text-[1.8vw] bg-transparent pb-0'><h2 className='bg-transparent font-extrabold opacity-60'>DSA </h2></div>
                <img className='w-[12vw] bg-transparent b rounded-xl ' src={AlgoPng} />
                <div className='glass2 absolute bottom-[-4.9vh] flex gap-2 shadow-lg w-full h-fit bg-[rgb(227,227,217)] group-hover:bottom-[0vh]  transition-all duration-1000 ease-in-out '>
                  <img width="96" height="96" src="https://img.icons8.com/pulsar-color/96/circled-up-2.png" alt="circled-up-2" className='absolute text-white w-[3vw]  bg-transparent bottom-[4.9vh] right-4' />  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>
                  <i class="fa-solid fa-star bg-transparent  text-yellow-500 text-[2vw]"></i>

                </div>
              </div>





            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
}

export default Skills;
