import React, { useState, useEffect } from 'react';

function CurrentTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update time every 1 second (1000ms)

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  // Format the time as you desire (e.g., HH:mm:ss)
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div className='bg-transparent'>
     
      <p className='bg-transparent'>{formattedTime}</p>
    </div>
  );
}

export default CurrentTime;
