import React, { useEffect } from 'react'
import './Project.css';
import LocomotiveScroll from 'locomotive-scroll';
import './Skills.css';
import LargeMenu from './LargeMenu';
import Menu from './Menu';
import { motion, useInView, easeInOut, easeIn, easeOut } from 'framer-motion';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
const Projects = () => {
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // This effect runs only on initial render
  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
      setShouldScrollToTop(false); // Prevent future scroll on re-renders
    }
  }, [shouldScrollToTop]);

  const secondpage = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const inView = useInView(secondpage, { once: true });
  const secondpageanimation = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 200 },
  }
  const handleMenuClick = () => {

    setMenuOpen(true);
  };
  const handleGoBackClick = () => {
    setMenuOpen(false);
  };


  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // 1400 milliseconds delay

    return () => clearTimeout(timeout);
  }, []);


  useEffect(() => {
    const scrollEl= document.querySelector('.h');
    if(scrollEl){
      console.log("successfully");
  const locoScroll=new LocomotiveScroll({
    el:scrollEl,
    smooth:true,
    multiplier:2.5,
  })}
  else{
    console.log("element not found");
  }
  
  }, [isLoading])


  return (
    <>
      {isLoading ? (
        <motion.div
          initial={{ y: "0%" }}
          animate={{
            y: ["100%", "0%", "0%", "-100%"],
            transitionEnd: { y: "-100%" }
          }}

          transition={{ duration: 4, times: [0, 0.25, 0.875, 1], delay: 0, ease: [easeInOut, easeInOut, easeInOut, easeIn] }}
          className='w-[100vw] h-[100vh] bg-[#a3a3a3] text-white flex justify-center items-center mainskill relative z-[10]'
        >
          <h1 data-text="Talha Chinchali">Talha Chinchali</h1>
        </motion.div>
      ) : (<div className='h-[100vh]'>
        <nav className="nav top-0 z-[9999]">
            <button className="text-white text-lg">Talha Chinchali</button>

            {isMenuOpen && <LargeMenu />}
            {!isMenuOpen && <h1 className="menuplus text-white h-fit text-lg cursor-pointerr " onClick={handleMenuClick} >Menu+</h1>}
          </nav>
          {isMenuOpen && <Menu isOpen={isMenuOpen} onClose={handleGoBackClick} />}
        <div className='w-full h h-fit project ' data-scroll-container >
          
          <div className='project1'>
            <motion.h1 initial={{opacity:0}} animate={{opacity:.6}} transition={{ease:easeInOut,duration:1}} className='text-white text-3xl font-extrabold heading'>College Bonafied Provider</motion.h1></div>
          <div className='flex   subpart'>
            <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.3}} className=' w-1/2 h-1/3 bg-black  '><h1 className='text-white   font-bold  bg-transparent'>Full Stack <br />Web Development</h1></motion.div>
            <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.6}} className=' w-1/2 h-1/3 bg-black justify-between flex flex-col gap-2'><h1 className='text-white  opacity-60 bg-transparent'>(Overview)</h1>
              <h1 className='text-white  leading-[2]  bg-transparent'>The Student Information System and Bonafide Certificate Generator is a comprehensive application designed to optimize administrative tasks related to student records and certificate issuance. By seamlessly managing student information and facilitating efficient bonafide certificate generation, this system aims to enhance operational efficiency and communication within educational institutions.</h1>
              <div class='bp '>
                <a href='https://bonafied-frontend.onrender.com/' target='_blank'
                  class='button hover:bg-white hover:text-black text-white  transition duration-500 ease-in-out absolute z-10 letstalkbutton'
                >Live</a>
              </div>

            </motion.div>

          </div>
          <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.9}} className='videodiv'>
            <img src='https://leverageedu.com/blog/wp-content/uploads/2020/02/Bonafide-Certificate-Format-for-Students-1024x735.png' className='h-[30vw]  cand w-[30vw]  ml-[30%]' />
          </motion.div>
          <div className='flex key'>

            <div className='text-white mt-[6%] ml-[2%] p-[40px] w-1/2 border'>
              <p><strong>Key Features:</strong>
                <br /><br />
                <strong>Student Information Management:</strong><br /> Capture and manage student details, including personal information, academic history, and extracurricular activities.
                <br /><br />
                <strong>Bonafide Certificate Generation:</strong><br /> Generate customized bonafide certificates for students based on their Unique Student Number (USN), including college logo, student particulars, purpose, and authorized signatures.

                <br /><br />
                <strong>Search and Reporting:</strong><br /> Provide intuitive search and reporting functionalities for quick access to student information and the generation of various reports, such as Bonafied certificates.
                <br /><br />
                <strong>User Authentication and Security:</strong><br /> Implement robust user authentication and role-based access controls to safeguard sensitive student data and ensure data privacy.

              </p>  </div>
            <div className='text-white mt-[6%] ml-[2%] p-[40px] w-1/2 border text-center'>
              <p><strong>Technology Stack:</strong>
                <br /><br />
                Backend: Node.js (Express.js)
                <br /><br />
                Frontend: React.js

                <br /><br />
                Database: MongoDB


              </p>

            </div>


          </div>
          <div className='nextprojectdiv mt-[10%]'>

            <Link to="/candy" className=' bg-transparent'>
              <motion.div ref={secondpage} className='relative overflow-hidden' variants={secondpageanimation} initial="hidden" animate={"visible"} transition={{ delay: 0.5, duration: 1 }} id="elem1" >
                <div className='m mt-10 bg-transparent text-white'><h1 className='bg-transparent absolute'>Next Project</h1>

                </div>
                <div id='elem'>

                  <h1 className=' cursor-default'>CANDY </h1>

                  <h5>2022</h5>
                </div>
              </motion.div></Link>

          </div>
        </div>
      </div>)}
    </>)
}

export default Projects