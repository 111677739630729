import React from 'react';
import { motion } from 'framer-motion';
import './LargeMenu.css';
import { Link } from 'react-router-dom';

const LargeMenu = ({largediv,originaldiv}) => {
  const itemAnimation = {
    hidden: { y: -10, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  const fun=()=>{
   
  
    
    
  }
  const clo=()=>{
   
    
     
     
   }
  return (
    <div className='flex flex-row bg-transparent'>
       <Link to="/projects" className=' bg-transparent'>
      <motion.h1
        className='largemenuHeading bg-transparent text-white  cursor-pointerr '
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        transition={{ duration: 0.3, delay: 0.25 * 0 }}
        onMouseEnter={fun}
        onMouseLeave={clo}
      >
        PROJECTS
      </motion.h1></Link>
      <Link to="/skills" className=' bg-transparent'>
      <motion.h1
        className='largemenuHeading bg-transparent text-white  cursor-pointerr '
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        transition={{ duration: 0.3, delay: 0.25 * 1 }}
        onMouseEnter={fun}
        onMouseLeave={clo}
      >
        SKILLS
      </motion.h1></Link>
     
      <Link to="/contact" className=' bg-transparent'>
      <motion.h1
        className='largemenuHeading bg-transparent text-white   cursor-pointerr '
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        transition={{ duration: 0.3, delay: 0.25 * 2 }}
        onMouseEnter={fun}
        onMouseLeave={clo}
      >
        CONTACT
      </motion.h1></Link>
      <Link to="/" className=' bg-transparent'>
      <motion.h1
        className='largemenuHeading bg-transparent text-white   cursor-pointerr '
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        transition={{ duration: 0.3, delay: 0.25 * 3 }}
        onMouseEnter={fun}
        onMouseLeave={clo}
      >
        HOME
      </motion.h1></Link>
    </div>
  );
};

export default LargeMenu;
