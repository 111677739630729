import React,{useEffect} from 'react'
import './Candy.css';
import LocomotiveScroll from 'locomotive-scroll';
import LargeMenu from './LargeMenu';
import Menu from './Menu';
import { motion,useInView,easeInOut, easeIn, easeOut } from 'framer-motion';
import { useState,useRef } from 'react';
import { Link } from 'react-router-dom';

import IndianCandy from './candy1.png'
const Candy = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);

  // This effect runs only on initial render
  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
      setShouldScrollToTop(false); // Prevent future scroll on re-renders
    }
  }, [shouldScrollToTop]);
  const secondpage=useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const inView=useInView(secondpage,{once:true});
  const secondpageanimation = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  }
  const handleMenuClick = () => {

    setMenuOpen(true);
  };
  const handleGoBackClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // 1400 milliseconds delay

    return () => clearTimeout(timeout);
  }, []);


  useEffect(() => {
    const scrollEl= document.querySelector('.h');
    if(scrollEl){
      console.log("successfully");
  const locoScroll=new LocomotiveScroll({
    el:scrollEl,
    smooth:true,
    multiplier:2.5,
  })}
  else{
    console.log("element not found");
  }
  
  }, [isLoading])



  return (
    <>
      {isLoading ? (
        <motion.div
        initial={{ y: "0%" }}
        animate={{
          y: ["100%", "0%", "0%", "-100%"],
          transitionEnd: { y: "-100%" }
        }}
               
                transition={{ duration: 4,times: [0, 0.25, 0.875, 1], delay: 0, ease:[easeInOut,easeInOut,easeInOut,easeIn]  }}
          className='w-[100vw] h-[100vh] bg-[#a3a3a3] text-white flex justify-center items-center mainskill relative z-[10]'
        >
          <h1 data-text="Talha Chinchali">Talha Chinchali</h1>
        </motion.div>
      ) : (<>
       <nav className="nav top-0">
        <button className="text-white text-lg">Talha Chinchali</button>

        {isMenuOpen && <LargeMenu />}
        {!isMenuOpen && <h1 className="menuplus text-white  text-lg cursor-pointerr " onClick={handleMenuClick} >Menu+</h1>}
      </nav>
      {isMenuOpen && <Menu isOpen={isMenuOpen} onClose={handleGoBackClick} />}
    <div className='w-full h h-fit project '>
     
      <div className='project1'>
        <motion.h1 initial={{opacity:0}} animate={{opacity:.6}} transition={{ease:easeInOut,duration:1}} className='text-white text-3xl font-extrabold heading'>Candy</motion.h1></div>
      <div className='flex   subpart'>
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.3}} className=' w-1/2 h-1/3 bg-black  '><h1 className='text-white   font-bold  bg-transparent'>Web Development</h1></motion.div>
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.6}} className=' w-1/2 h-1/3 bg-black justify-between flex flex-col gap-2'><h1 className='text-white  opacity-60 bg-transparent'>(Overview)</h1>
          <h1 className='text-white leading-[2]  bg-transparent'> The Animated Candy-themed React Frontend UI project is an impressive showcase of frontend development skills, featuring an engaging website centered around the enchanting world of candies. This project demonstrates proficiency in React, UI/UX design, static web development, and innovative animations powered by Framer Motion. The project is also enriched by the creative use of Tailwind CSS for styling, creating a visually appealing and responsive candy-themed design.</h1>
          <div class='bp '>
            <a target='_blank'
             href='https://candy-talha1.onrender.com/' class='button cursor-pointer hover:bg-white hover:text-black text-white  transition duration-500 ease-in-out absolute z-10 letstalkbutton'
            >Live</a>
          </div>
          
        </motion.div>
        
      </div>
    
      <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:easeInOut,duration:1,delay:0.9}} className='videodiv'>
      <img src={IndianCandy} className='h-[30vw]  cand w-[30vw]  ml-[40%]' />
      </motion.div>
      <div className='flex justify-center items-center'>


<div className='text-white mt-[6%] ml-[2%] p-[40px] w-1/2 border tech'>
<p><strong>Technology Stack:</strong>
<br /><br />
<strong>React:</strong><br />
 The project harnesses React's capabilities to create modular components and manage dynamic content.
 <br /><br />
 <strong>Tailwind CSS:</strong> <br />
Tailwind CSS is used to craft the responsive and visually appealing candy-themed design, optimizing styling efficiency.
<br /><br />
<strong>Framer Motion:</strong><br />
 Innovative animations powered by Framer Motion enrich the user experience, adding engagement and immersion.
 <br /><br />
 <strong>React Router:</strong><br /> Navigation is seamlessly handled by the React Router library, ensuring smooth transitions within the single-page application.
</p> 

</div>


</div>

     

    
    </div></>)}
    </>
  )
}

export default Candy