import React,{useState,useEffect} from 'react';
import LargeMenu from './LargeMenu';
import Menu from './Menu';
import {motion,easeInOut,easeOut,easeIn} from 'framer-motion';

const Contact = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleMenuClick = () => {

    setMenuOpen(true);
  };
  const handleGoBackClick = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // 1400 milliseconds delay

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {isLoading ? (
        <motion.div
        initial={{ y: "0%" }}
        animate={{
          y: ["100%", "0%", "0%", "-100%"],
          transitionEnd: { y: "-100%" }
        }}
               
                transition={{ duration: 4,times: [0, 0.25, 0.875, 1], delay: 0, ease:[easeInOut,easeInOut,easeInOut,easeIn]  }}
          className='w-[100vw] h-[100vh] bg-[#a3a3a3] text-white flex justify-center items-center mainskill relative z-[10]'
        >
          <h1 data-text="Talha Chinchali">Talha Chinchali</h1>
        </motion.div>
      ) : ( <div className='w-full h h-fit project '>
  <nav className="nav top-0 z-[9999]">
    <button className="text-white text-lg">Talha Chinchali</button>

    {isMenuOpen && <LargeMenu />}
    {!isMenuOpen && <h1 className="menuplus text-white h-fit text-lg cursor-pointerr " onClick={handleMenuClick} >Menu+</h1>}
  </nav>
  {isMenuOpen && <Menu isOpen={isMenuOpen} onClose={handleGoBackClick} />}
  <div className='project1'>
        <h1 className='text-white text-3xl font-extrabold heading'>Contact</h1></div>
        <div className='text-white mt-[6%] ml-[10%] p-[40px] w-[80vw] border'><p>

       
        Feel free to reach out to me if you have any inquiries or opportunities. I'm always open to new projects and collaborations. You can contact me via email:
<br/><br/>
Email:  [talhachinchali29@gmail.com]
<br/><br/>
I will do my best to respond to your message as soon as possible. Thank you for visiting my portfolio! </p></div>
  </div>)}
  </>
  )
}

export default Contact