
import './App.css';
import LocomotiveScroll from 'locomotive-scroll';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { easeInOut } from 'framer-motion';
import { motion, useInView } from 'framer-motion';
import Menu from './Menu';
import LargeMenu from './LargeMenu';
import CurrentTime from './CurrentTime';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'remixicon/fonts/remixicon.css';
import Talhapng from './portfolioimgtalha.png'

import { Link } from 'react-router-dom';



function App() {
  const secondpage = useRef(null);
  const aboutpage = useRef(null);
  const aboutview = useInView(aboutpage, { once: true });
  const inView = useInView(secondpage, { once: true });
  const [scroll, setScroll] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });


  const secondpageanimation = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  }
  const aboutpageanimation = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }
  const resetColor = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.backgroundColor = 'white'; // or any other initial background color you want
  };
  const colourchange = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.backgroundColor = 'black';
  };
  const largediv = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.scale = 4;


  }
  const originaldiv = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.scale = 1;
  }
  const Menulargediv = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.scale = 2;


  }
  const Menuoriginaldiv = () => {
    const dotElement = document.getElementById('dot');
    dotElement.style.scale = 1;
  }
  const dotElement = document.getElementById('dot');

  useEffect(() => {
    const initScroll = new LocomotiveScroll();
    setScroll(initScroll);

    return () => {
      if (initScroll) {
        initScroll.destroy();
      }
    };
  }, []);

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });

  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const handleGoBackClick = () => {
    setMenuOpen(false);
  };



  var diff;
  document.querySelectorAll('#elem').forEach(function (elem) {

    elem.addEventListener("mouseenter", function (dets) {
      gsap.to(elem.querySelector("h1"), {
        opacity: .2,
        x: 100,
        duration: 1, // Adjust as needed
        ease: "power3.inOut",
      })
    });

    elem.addEventListener("mousemove", function (dets) {
      diff = dets.clientY - elem.getBoundingClientRect().top;

      // Limit the diff value to a specific threshold (e.g., 100 pixels)



      gsap.to(elem.querySelector("img"), {
        opacity: .6,

        top: diff - 200,
        left: dets.clientX - 500,
        transform: "translateZ(0)",
        duration: 0.1,
        ease: "power3.inOut"

      });
    });

    elem.addEventListener("mouseleave", function () {
      gsap.to(elem.querySelector("img"), {
        opacity: 0,
        duration: 1.0,
        ease: "power3.inOut"
      });
      gsap.to(elem.querySelector("h1"), {
        opacity: 1,
        x: 0,
        duration: 1, // Adjust as needed
        ease: "power3.inOut",
      })
    });
  });


useEffect(() => {
  const scrollEl= document.querySelector('[data-scroll-container]');
const locoScroll=new LocomotiveScroll({
  el:scrollEl,
  smooth:true,
  multiplier:2.5,
})

}, [])



  return (
<>
<div className='dot-wrapper' >
        <div id="dot" className='w-3 h-3 bg-white rounded-full z-50 fixed dot ' innerText='View' style={{ top: mousePosition.y, left: mousePosition.x }}></div></div>
   
    <nav className="nav top-0"  onMouseMove={handleMouseMove}>
          <button className="text-white text-lg">Talha Chinchali</button>

          {isMenuOpen && <LargeMenu largediv={Menulargediv} originaldiv={Menuoriginaldiv} />}
          {!isMenuOpen && <h1 className="menuplus h-fit text-white text-lg bg-transparent cursor-pointerr " onClick={handleMenuClick}  >Menu+</h1>}
        </nav>
    <div className="App overflow-hidden scroll-smooth " data-scroll-container onMouseMove={handleMouseMove}>


      <i class="fa-solid fa-square-up-right"></i>
      <i class="fa-solid fa-arrow-trend-up"></i>
       <div className=" hero ">
        
        {isMenuOpen && <Menu isOpen={isMenuOpen} onClose={handleGoBackClick} />}

        <div initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut' }} id="heading">
          <div className='bg-black w-fit overflow-hidden'><motion.h1 initial={{ y: 200, opacity: 0 }} animate={{ y: 0, opacity: .6 }} transition={{ duration: 1, ease: 'easeInOut' }}>
            Software
          </motion.h1></div>
          <div id="secondh1main" className='bg-black w-fit overflow-hidden'>
            <motion.h1 initial={{ y: 200, opacity: 0 }} animate={{ y: 0, opacity: .6 }} transition={{ duration: 1, ease: 'easeInOut' }} id="secondh1">
              Developer
            </motion.h1>
            <div className='bg-black overflow-hidden'><motion.h5 initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: .6 }} transition={{ duration: .5, ease: 'easeInOut', delay: 1.5 }}>
              Based in India
            </motion.h5></div>
          </div>
        </div>
        <div className="h-fit overflow-hidden">
          <motion.div initial={{ y: 200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: 'easeInOut', delay: .5 }} id="chotiheadings">
            <h5>availaible for freelance</h5>
            <h5>work from may 23</h5>

          </motion.div>
        </div>
        <div id="herofooter" className="text-white absolute w-full bottom-10 flex justify-between p-4 pl-9 pr-9 ">

          <div className='downloadtext'> <button className='font-[600] text-[.9vw] uppercase' >Download my resume</button> <i class="fa-solid fa-arrow-right text-xl"></i></div>
          <div className='flex flex-row' onMouseLeave={() => { dotElement.style.display = "block" }} onMouseEnter={() => { dotElement.style.display = "none" }} onClick={() => { window.location.href = 'https://drive.google.com/uc?export=download&id=1SCkPBXdPwURFvfSnmmpuOEesCoHRJYo3'; }}>
            <div className='rounded-full w-[1px] h-[1px] bg-slate-500 flex justify-center relative items-center p-[15px] opacity-100 resumediv'  >
              <button><i class="fa-solid fa-arrow-down-long bg-transparent  text-black text-[.8vw] hover:text-white hover:font-[900] hover:opacity-100 cursor-pointer " ></i>

              </button>

            </div>

            <div className='rounded-full w-[1px] h-[1px] bg-slate-500 flex justify-center items-center p-[15px] opacity-100 resumediv'>
              <button><i class="fa-solid fa-arrow-up bg-transparent text-black text-[.8vw] hover:text-white hover:font-[900] cursor-pointer"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div id="second" onMouseEnter={() => { dotElement.style.display = "none" }} onMouseLeave={() => { dotElement.style.display = "block" }}>
        <Link to="/projects" className=' bg-transparent'>
          <motion.div ref={secondpage} className='relative overflow-hidden' variants={secondpageanimation} initial="hidden" animate={inView ? "visible" : "hidden"} transition={{ delay: 0.5, duration: 1 }} id="elem" >

            <img className='absolute   ' src='https://cdn.pixabay.com/photo/2021/05/01/16/20/iron-man-6221442_1280.jpg' alt=' is there' />

            <h1 className=' cursor-default'>Projects </h1>

            <h5>2022</h5>
          </motion.div></Link>
        <Link to="/skills" className=' bg-transparent'>
          <motion.div className='relative overflow-hidden mb-[0px]' variants={secondpageanimation} initial="hidden" animate={inView ? "visible" : "hidden"} transition={{ delay: 0.8, duration: 1 }} id="elem" >
            <img className='absolute  ' alt=' is there' src='https://mir-s3-cdn-cf.behance.net/projects/max_808/8dcd1a96693973.Y3JvcCwxMTgwLDkyMywzNjgsNzg.png' />
            <h1 className='  cursor-default'>Skills</h1>
            <h5>2022</h5>
          </motion.div></Link>
        <Link to="/contact" className=' bg-transparent'>
          <motion.div className='relative lastline overflow-hidden bg-black ' variants={secondpageanimation} initial="hidden" animate={inView ? "visible" : "hidden"} transition={{ delay: 1.0, duration: 1 }} id="elem"  >
            <img className='absolute' alt=' is there' src='https://wallpaperaccess.com/full/55572.jpg' />
            <h1 className='  cursor-default' >CONTACT</h1>
            <h5>2022</h5>

          </motion.div></Link>
      </div>
      <div ref={aboutpage} id="about">
        <motion.div variants={aboutpageanimation} initial="hidden" animate={aboutview ? "visible" : "hidden"} transition={{ delay: 1, duration: 1.5, ease: easeInOut }} className='  ivg '>
          <img src={Talhapng} className='opacity-70' alt='is there' />
        </motion.div>
        <motion.div variants={aboutpageanimation} initial="hidden" animate={aboutview ? "visible" : "hidden"} transition={{ delay: 0.5, duration: 1.5, ease: easeInOut }} className='abouttext text-white cursor-default'>
          <h1>(ABOUT ME)</h1>
          <p className=' cursor-default'>I'm a Full Stack developer with a passion for creating products that not only look good but also solve real problems. When I'm not doing any projects, you can find me binge-watching a Netflix series or playing video games. My design philosophy is simple: make it visually appealing, functional, and bring a smile to people's faces. I'm the developer you want on your team if you want to make people say 'I need that in my life!'.</p>
          <div class='bp'>
            <a href='mailto:talhachinchali29@gmail.com'
              class='button hover:bg-white hover:text-black  transition duration-500 ease-in-out absolute z-10 letstalkbutton cursor-pointer'
              onMouseMove={colourchange}
              onMouseLeave={resetColor}
            >Let's talk</a>
          </div>

        </motion.div>
      </div>


      <div className="footer text-white  flex w-full justify-between items-center ">
        <div className="footerleft flex gap-10">
          <h3 className="font-extrabold text-lg ">2023</h3>
          <h3 className="font-extrabold text-lg "><CurrentTime /> </h3>
        </div>
        <div className="links flex  justify-between items-center cursor-pointer">
          <a href='https://www.instagram.com/talha.chinchali/' target='_blank' className='cursor-pointer'>Instagram</a>
          <a href='https://github.com/talhachinchali' target='_blank' className='cursor-pointer'>GitHub</a>
          <a href='https://www.linkedin.com/in/talha-chinchali-148902254/?originalSubdomain=in' target='_blank' className='cursor-pointer'>LinkedIn</a>

        </div>
      </div>
    </div>
    </>
  );
}

export default App;
